/**
 * CTA w/ Background Flex Layout
 * DBS>Interactive
 */

.cta-with-background {
    position: relative;
    
    hr {
        border-top: 2px solid $gold;
        width: 5rem;
    }
    
    .content {
        padding-left: #{$spacing * 5};
        position: relative;
        z-index: 10;
    }
    
    .eyebrow {
        color: $gold;
    }
    
    .image-wrapper {
        height: 100%;
        position: absolute;
        top: 0;
        
        img {
            height: 100%;
            max-width: none;
            object-fit: cover;
            object-position: left;
            width: 100vw;
        }
        
        &.gold-gradient {
            &::before {
                background: linear-gradient(72deg, rgba(4, 4, 4, 0.00) 4.11%, rgba(0, 0, 0, 0.51) 55.08%);
                content: '';
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
            
            .overlays-one,
            .overlays-two {
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
            }
            
            .overlays-one {
                &::before {
                    background: linear-gradient(0deg, #000 0%, #000 100%), linear-gradient(90deg, rgba(4, 4, 4, 0.00) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
                    mix-blend-mode: color;
                    content: '';
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 2;
                }
                
                &::after {
                    background: linear-gradient(108deg, rgba(126, 91, 45, 0.44) 10.87%, rgba(239, 214, 131, 0.44) 82.01%), linear-gradient(0deg, #EFD683 0%, #EFD683 100%), linear-gradient(90deg, rgba(4, 4, 4, 0.00) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
                    mix-blend-mode: overlay;
                    content: '';
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 3;
                }
            }
            
            .overlays-two {                
                &::before {
                    opacity: 0.76;
                    background: linear-gradient(106deg, #7E5B2D 19.5%, #EFD683 87.63%), linear-gradient(0deg, #EFD683 0%, #EFD683 100%), linear-gradient(90deg, rgba(4, 4, 4, 0.00) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
                    mix-blend-mode: overlay;
                    content: '';
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 4;
                }
                
                &::after {
                    background: linear-gradient(278deg, rgba(4, 4, 4, 0.04) 0%, #37260F 73.19%);
                    content: '';
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 5;
                }
            }
        }
    } 
    
    .main-heading {
        font-family: $font-lato;
        text-transform: uppercase;
    }
    
    @include media($screen-sm) {
        &.bg-dark.layout {            
            padding-bottom: #{$spacing * 4};
            padding-top: #{$spacing * 4};
        }
        
        &.dots {
            .content::before {
                background-image: url('/images/dots2.svg');
                background-repeat: no-repeat;
                content: '';
                height: 100%;
                left: -5rem;
                pointer-events: none;
                position: absolute;
                top: 65%;
                transform: translateY(-50%);
                width: 100%;
                z-index: 6;
            }
        }
        
        .content.half {
            width: 75%;
        }
    }
    
    @include media($screen-md) {
        .content.half {
            width: 65%;
        }
    }
    
    @include media($screen-lg) {
        .content.half {
            width: 50%;
        }
    }
    
    @include media($screen-sm-max, 'max') {
        .content {
            padding: 0;
            padding-bottom: 30%;
        }
        
        .image-wrapper {
            img {
                object-fit: contain;
                object-position: bottom;
            }
            
            &.gold-gradient {
                .overlays-two {
                    &::after {
                        background: linear-gradient(0deg,rgba(4,4,4,.04) -15%,#37260f 25%);
                    }
                }
            }
        }
    }
}
