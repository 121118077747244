.location-finder {
    position: relative;
    
    #search-form {
        .search-form__container {
            input:-internal-autofill-selected {
                background-color: black;
            }
        }
        
        .search-form__form {
            width: auto;
        }
        
        #search-input {
            border: 0;
            color: $grayDarkest;
            margin: 0;
            padding: calc(.666rem);
            transition: border-radius .5s ease, width .5s ease;
            
            &::placeholder {
                color: $grayDark;
            }
        }
    }
    
    &__content {
        display: flex;
        gap: #{$spacing * 3};
        position: relative;
        z-index: 10;
    }
    
    &__heading {
        h2 {
            font-weight: 600;
        }
        
        .eyebrow {
            color: $gold;
            font-weight: 400;
            padding-left: 2rem;
            position: relative;
            text-transform: capitalize;
            
            &:before {
                background: url('/icons/locations.svg') no-repeat;
                content: '';
                height: 1.25rem;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1.25rem;
            }
        }
    }
    
    &__list {
        font-size: 1.125rem;
        margin-bottom: #{$spacing * 2};
        padding-left: 0;
        position: relative;
        z-index: 10;
        
        li {
            list-style-type: none;
            padding-left: 2.5rem;
            position: relative;
            
            a {
                font-weight: 400;
                text-decoration: none;
            }
            
            &:before {
                background: url('/icons/list-marker-gold.svg') no-repeat;
                content: '';
                height: 5px;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1.25rem;
            }
            
            &:not(:last-child) {
                margin-bottom: $spacing;
            }
        }
    }
    
    &__lists {
        margin-bottom: 0;
        padding-left: 0;
        
        >li {
            list-style-type: none;
            margin-bottom: #{$spacing / 2};
        }
    }
    
    &:not(.display-list) {
        #search-form {
            margin: 0 auto;
            max-width: #{$spacing * 11};
        }
        
        .location-finder__content {
            justify-content: center;
            padding-left: 0;
        }
        
        .location-finder__heading {
            text-align: center;
            width: auto;
        }
        
        .main-heading {
            color: $yellow;
        }
    }
    
    .contain {
        position: relative;
        z-index: 5;
    }
    
    .image-wrapper {
        height: 100%;
        position: absolute;
        top: 0;
        
        img {
            height: 100%;
            max-width: none;
            object-fit: cover;
            object-position: left;
            width: 100vw;
        }
        
        &::after {
            background: linear-gradient(180deg, $blackLight 0%, transparent 30%, transparent 100%);
            mix-blend-mode: multiply;
            content: '';
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
    }
    
    @include media($screen-xs) {
        &__lists {
            columns: 2;
        }
    }
    
    @include media($screen-sm) {
        &__content {
            padding-left: #{$spacing * 5};
        }
        
        &__heading {
            width: 40%;
        }
        
        &__lists {
            columns: 2;
            width: 60%;
        }
        
        &.bg-dark.layout {            
            padding-bottom: #{$spacing * 4};
            padding-top: #{$spacing * 4};
        }
        
        &.dots {
            .location-finder__content::before {
                background-image: url('/images/dots2.svg');
                background-repeat: no-repeat;
                bottom: -9.7rem;
                content: '';
                height: 100%;
                left: -5rem;
                position: absolute;
                width: 6.1rem;
                z-index: 6;
            }
        }
    }
    
    @include media($screen-md) {
        &__content {
            padding-bottom: #{$spacing * 3};
            padding-top: #{$spacing * 3};
        }
        
        &.dots {
            .location-finder__content::before {
                bottom: -18.7rem;
            }
        }
    }
    
    @include media($screen-sm-max, 'max') {
        &__content {
            flex-direction: column;
        }
    }
}