/**
 * Two Columns Flex Layout
 * DBS Interactive
 */

.two-columns {
    
    hr {
        border-top: 2px solid $gold;
        margin: $spacing 0;
        width: 5rem;
    }
    
    &__left,
    &__right {
        position: relative;
    }
    
    &.left-framed.big-o-left,
    &.big-o-left {
        .two-columns__left {
            position: relative;
            
            h1, h2, h3, h4, h5, h6, hr, p {
                position: relative;
            }
                        
            &:before {
                background-position: center;
                background-image: url('/images/big-o.svg');
                background-size: 100%;
                background-repeat: no-repeat;
                content: '';
                height: 215%;
                left: 50%;
                pointer-events: none;
                position: absolute;
                top: 50%;
                width: 100%;
                z-index: 0;
                transform: translate(-50%, -50%);
            }
        }
    }

    &.left-framed.big-o-right,
    &.big-o-right {
        .two-columns__right {
            position: relative;
            
            h1, h2, h3, h4, h5, h6, hr, p {
                position: relative;
            }
                        
            &:before {
                background-position: center;
                background-image: url('/images/big-o.svg');
                background-size: 100%;
                background-repeat: no-repeat;
                content: '';
                height: 215%;
                left: 50%;
                pointer-events: none;
                position: absolute;
                top: 50%;
                width: 100%;
                z-index: 0;
                transform: translate(-50%, -50%);
            }
        }
    }

    &.left-framed {
        .contain {
            gap: #{$spacing * 2};
        }
        
        .two-columns__left {
            margin: 0 1rem 4rem;
            position: relative;
            
            
            .container {                
                background-color: white;
                color: $gray;
                padding: $spacing;
                position: relative;
                
                h1,h2,h3,h4,h5,h6 {
                    color: $gray;
                    font-family: $font-lato;
                }
            }
            
            &::before {
                background: linear-gradient(180deg, #9C712B 0%, $orange 100%);
                content: '';
                height: 85%;
                position: absolute;
                left: -1rem;
                top: calc(15% + 1rem);
                width: calc(100% + 2rem);
            }
        }
    }

	@include media($screen-sm-max, 'max') {
		&__left {margin-bottom: $spacing;}
	}

	@include media($screen-sm) {
        &.left-framed {
            .two-columns__left {
                margin: 0 #{$spacing * 2};
            }
        }
        
		&__left,
		&__right {
			width: calc(50% - #{$spacing*2});
		
			&.contain-img { img {@include object-fit(contain, center);} }
			&.cover { img {@include object-fit(cover, center);} }
            
            &.contain-img,
			&.cover {align-self: stretch;}
		}
	}
    
    @include media($screen-md) {
        &__left > img:only-child,
        &__right > img:only-child {
            height: 100%;
        }
    }
}