/**
 * Three Columns Flex Layout
 * DBS>Interactive
 */

.layout.three-columns {
    background-color: $white;
    
    h1, h2, h3 {
        color: $gold;
    }
    
    p {
        color: $gray;
    }

	.three-columns__left,
	.three-columns__middle,
	.three-columns__right {
		width: 100%;

		@include media($screen-sm) {
			width: 33.33%;
		}
	}
    
    .contain {
        gap: $spacing;
    }

	@include media($screen-sm-max, 'max') {
		.column .cell {padding-left: 0; padding-right: 0;}
	}

	@include media ($screen-md) {
		/**
		 * This little bit of hackery makes the edge of background images and
		 * background colors line up with the container content when a contain
		 * class is on the half and half layout, but not when the
		 * 'contain-full-width' reset class is used.
		 */
		&[class*=contain]:not([class~=contain-full-width]) [class*=bg-] {
			&.column:first-of-type,
			&.column:last-of-type { width: calc(33.3333% - #{$spacing}); }
			&.column:first-of-type { margin-left: $spacing; }
			&.column:last-of-type { margin-right: $spacing; }
		}

		.columns {margin: 0 -#{$spacing};}
	}
}
