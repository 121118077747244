.home-hero {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    position: relative;
    
    &__background-image {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        
        img {
            height: 100%;
            max-width: none;
            object-fit: cover;
            width: 100%;
        }
    }
    
    &__brand {
        cursor: pointer;
    }
    
    &__intro {
        position: relative;
        width: 90%;
    }
    
    @include media($screen-sm) {        
        &__intro {
            width: 48%;
        }
        
        .contain {
            padding: #{$spacing * 12} $spacing 0;
        }
    }
    
    &.citadel-healthcare,
    &.pavilion-healthcare {
        background-color: $white;
        
        a {
            color: $yellowLight;
            text-decoration: none;
        }
        
        &::before {
            background: linear-gradient(0deg, $black 0%, $grayDarkest 100%);
            content: '';
            height: 60%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
        
        .contain {
            max-width: 90rem;
            padding: #{$spacing * 6} $spacing 0 0;
        }
        
        .home-hero__background-image {
            max-width: 67.5rem;
        }
        
        .home-hero__intro {
            background: linear-gradient(270deg, $purpleDark 0%, $black 100%);
            border-right: 1rem solid $gold;
            padding: $spacing;
        }
        
        @include media($screen-sm) {
            .contain {
                padding: #{$spacing * 11} $spacing #{$spacing * 5} 0;
            }
            
            .home-hero__background-image {
                left: unset;
                right: 0;
                width: 75%;
            }
            
            .home-hero__intro {
                padding: #{$spacing * 2} $spacing #{$spacing * 2};
            }
            
            &.dots {
                &::after {
                    background-image: url('/images/dots2.svg');
                    background-repeat: no-repeat;
                    bottom: -9rem;
                    content: '';
                    height: 25rem;
                    right: 0;
                    position: absolute;
                    width: 6.1rem;
                    z-index: 6;
                }
            }
        }
        
        @include media($screen-md) {
            .home-hero__intro {
                padding: #{$spacing * 2} #{$spacing * 3} #{$spacing * 2} #{$spacing * 5};
            }
        }
        
        @include media(90rem) {
            .home-hero__background-image {
                left: calc(50% - 22.5rem);
                right: unset;
            }
            
            &.dots {
                &::after {
                    right: calc(50vw - 45rem);
                }
            }
        }
        
        @include media($screen-sm-max, 'max') {
            .home-hero__background-image:before {                
                background: linear-gradient(0deg, rgba(39, 41, 43, 0.00) 0%, #0A0A0B 100%);
                content: '';
                height: 20%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
            
            .home-hero__background-image:after {                
                background: linear-gradient(180deg, rgba(39, 41, 43, 0.40) 0%, #27282A 100%);
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
            
            .home-hero__intro {
                background: transparent;
                border: 0;
            }
        }
    }
    
    &.omnia-hcg {

        .contain {
            padding: #{$spacing * 10} $spacing 0;
            position: relative;
            
            &::after {
                background: linear-gradient(180deg, rgba(39, 41, 43, 0.00) 1.98%, #27282A 101.98%);
                content: '';
                height: 21rem;
                left: -500%;
                position: absolute;
                bottom: 0;
                width: 1000%;
            }
        }
        
        .home-hero__background-image {
            img {
                transform: rotateY(-180deg);
            }
            
            &::before {
                background: linear-gradient(0deg, rgba(39, 41, 43, 0.00) 0%, $black 100%);
                content: '';
                height: 21rem;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
            
            &::after {
                background: linear-gradient(180deg, rgba(39, 41, 43, 0.00) 1.98%, $grayDarkest 101.98%);
                content: '';
                height: 21rem;
                left: 0;
                position: absolute;
                bottom: 0;
                width: 100%;
                z-index: 1;
            }
        }
        
        .home-hero__brand {
            align-items: center;
            background-color: black;    // note: #000, not $black
            display: flex;
            justify-content: center;
            padding: $spacing;
            width: calc(50% - #{$spacing / 2});
            
            img {
                max-height: 8rem;
                object-fit: contain;
            }
        }
    
        .home-hero__brands {
            display: flex;
            flex-wrap: wrap;
            gap: $spacing;
            justify-content: space-between;
            margin-top: #{$spacing * 9};
            position: relative;
            width: 100%;
            z-index: 1;
            
            &::after {
                background-image: url('/images/dots2.svg');
                background-repeat: no-repeat;
                content: '';
                height: 100%;
                right: -90%;
                position: absolute;
                top: -120%;
                transform: 0;
                width: 100%;
                z-index: 6;
            }
        }
        
        .home-hero__intro {            
            h2 {
                position: relative;
            }
                
            &:before {
                background: linear-gradient(54deg, rgba(16, 16, 16, 0.00) 16.96%, rgba(25, 26, 28, 0.66) 66.97%), linear-gradient(8deg, #EFD683 17.22%, #7E5B2D 37.05%);
                content: '';
                height: 10rem;
                left: calc(1rem + 3px);
                position: absolute;
                bottom: -60%;
                width: 2px;
            }
            
            &:after {
                background: url('/icons/triangle.svg') center no-repeat;
                bottom: calc(-60% - .9rem);
                content: '';
                height: .5rem;
                left: 1rem;
                position: absolute;
                width: .5rem;
            }
        }
        
        @include media($screen-sm) {
            .home-hero__brand {
                width: calc(30% - #{$spacing * 3/4});
            }
            
            .home-hero__brands {
                width: calc(100% - #{$spacing * 3});
                
                &::after {
                    right: -109%;
                    top: 40%;
                    transform: translateY(-50%);
                }
            }
        }
        
        @include media($screen-md) {
            .home-hero__brand:nth-child(2) {
                a {
                    padding: 1.7rem;
                }
            }
        }
    }
}
