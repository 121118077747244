/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

.site-footer {
    clear: both;
    font-size: .875rem;
	margin-top: auto; // Sticky footer
	overflow: hidden;
    position: relative;

	&__container {
		padding: #{$spacing*2} $spacing #{$spacing / 2};
	}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	// Default link styles
	a {
		text-decoration: none;
		
		&:hover { text-decoration: underline; }
	}

	.site-footer__logo svg {
		display: block;
		max-width: 10em;
		padding-bottom: 1em;
	}

	&__content {
		display: inline-block;
	}

	&__business {
		ul, li {
			display: block;
		}
	}

	&__navigation {
        font-family: $font-lato;

		.footer-menu__item {
			a {
				display: block;
				font-weight: 400;
				padding: .25rem 0;

				&:after {
					background: $grayDarkest;
					content: '';
					display: block;
					height: 1px;
					position: relative;
						top: 3px;
					transform: scaleX(0);
					transform-origin: 100% 0;
					transition: transform .25s ease-in-out;
				}
			}

			&.toplevel {
				margin-bottom: 1rem;

				& > a {color: $white; font-weight: 700; letter-spacing: 1px;}
			}
		}
        
        .submenu-toggle {
            display: none;
        }
    
        >ul {
            column-gap: #{$spacing * 2};
            
            >.footer-menu__item>a:after {
                background: $purpleDark;
            }

            >li>a {
                margin-bottom: 1rem;
                padding: 0 !important;
            }
        }
        

		li { display: block; }
	}

	.social-media {
		margin: 1rem 0;

		&__link {display: inline-block;}

		a {
            display: block; width: 2em;
            
            &:active,
            &:focus,
            &:hover {
                svg {
                    path {
                        fill: $grayDark;
                    }
                }
            }
        }
        
        li:not(:last-child) {
            margin-right: 1rem;
        }

		svg {            
            height: 1rem;
            
            path {
                transition: .5 color ease;
            }
        }
	}

	&__credits {
		display: flex;
        align-items: flex-end;
        justify-content: space-between;
		padding: 1em 0 0;
		width: 100%;
        
		.legal-footer {
            display: inline-block;
            font-family: $font-lato;

			.legal-menu__item {
                a {
                    font-weight: 400;
                }
                

			}
		}
	}
    
    &.bg-dark {
        .site-footer__navigation {
            .footer-menu__item {
                a {
                    &:after {
                        background: $white;
                    }
                }                
            }

            >ul>.footer-menu__item>a:after {
                background: $yellow;
            }
        }
        
        .social-media {
        	a {
                &:active,
                &:focus,
                &:hover {
                    svg {
                        path {
                            fill: $blueLight;
                        }
                    }
                }
            }

        	svg {            
                path {
                    fill: $white;
                }
            }
        }
    }

	@include media($screen-sm) {
        // padding: #{$spacing * 2} 0 0;
        
        &__business {
            column-gap: #{$spacing * 2};
            columns: 2;
        }
        
        &__content {
            width: 40%;
            
            >div>a {
                color: $purpleDark;
            }
        }
        
        &__credits {
            .legal-footer {
                ul, li {
                    display: inline-block;
                }
                
                .legal-menu__item {                    
                    &:not(:last-child) {
                        margin-right: #{$spacing * 2};
                    }
                }
            }
        }
        
        &__main {
            padding-bottom: #{$spacing * 2};
        }
        
		&__navigation {
            width: 100%;
            
			> ul {
                align-items: flex-start;
				display: flex;
				justify-content: space-between;
                
                >li {
                    min-width: 8rem;
                    
                    >a {
                        color: $purpleDark;
                        font-weight: bold !important;
                        text-transform: uppercase;
                    }
                }
			}

			.footer-menu__item {
                a {
                    &:hover,
                    &:focus {
                    	text-decoration: none;
                    
                    	&:after {
                    		transform: scaleX(1);
                    		transform-origin: 0 100%;
                    	}
                    }
                }
                
                .toplevel {padding-right: $spacing;}
            }
		}
        
        .social-media {
            margin: #{$spacing * 2} 0;
            
            a {
                &:active,
                &:focus,
                &:hover {
                    svg {
                        path {
                            fill: $goldDark;
                        }
                    }
                }
            }
            
            svg {
                path {
                    fill: $gold;
                }
            }
        }
        
        &::after {
            background-color: $blueLight;
            content: '';
            height: 1px;
            position: absolute;
            bottom: #{$spacing * 2};
            width: 100%;
        }
        
        &.bg-dark {
            padding: #{$spacing * 2} 0 0;
            
            .site-footer__content {
                >div>a {
                    color: $yellow;
                }
            }
            
            .site-footer__navigation {
            	> ul {
                    >li>a {
                        color: $yellow;
                    }
            	}
            }
            
            .social-media {
                a {
                    &:active,
                    &:focus,
                    &:hover {
                        svg {
                            path {
                                fill: $yellow;
                            }
                        }
                    }
                }
            }
            
            &::before {
                background-color: $blueLight;
                content: '';
                height: 1px;
                position: absolute;
                top: #{$spacing * 2};
                width: 100%;
            }
        }
	}
    
    @include media($screen-md) {
        &__main {
            display: flex;
            gap: #{$spacing * 4};
        }
        
        &__navigation {
            width: 60%;
            
        	> ul {
        		justify-content: space-evenly;
        	}
        }
    }
    
	@include media($screen-sm-max, 'max') {
        text-align: center;
        
        &__business {
            .site-footer__phone-email {
                margin-top: 1rem;
            }
        }
        
        &__container {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }
        
        &__credits {
            background-color: $blueLight;
            padding: 1rem $spacing;
            
            .legal-footer {
                margin-bottom: $spacing;
            }
        }

		&__navigation {
            background-color: $blueLight;
            margin: 2rem 0 0;
            
			.footer-menu__submenu {display: none;}

			.footer-menu__item {
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                
				&--parent {
					position: relative;

					&.open {
						.footer-menu__submenu {display: block;}
					}
				}
                
                &:first-child {
                    padding-top: 1rem;
                }
                
                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 2rem;
                }
                
                &:not(:last-child) {
                    border-bottom: 1px solid #fff;
                }
                
                a {
                    margin-bottom: 0;
                }
			}

			.submenu-toggle {
				top: -.75rem;

				svg path {
                    fill: $grayDarker;
                }
			}
            
            >ul>li>a {
                font-size: 1rem;
            }
		}
        
        &.bg-dark {
            .site-footer__credits {
                background-color: $blueDarker;
            }
            
            .site-footer__navigation {
                background-color: $blueDarker;
            }
        }
        
        .social-media {
            justify-content: center;
        }
	}
}
