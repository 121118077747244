.featured-text-strip {
    background-color: $purple;
    color: $white;
    position: relative;
    z-index: 10;    // so the border around featured-text overlaps adjacent layouts
    
    &::after {
        content: '';
        height: 100%;
        left: 35%;
        position: absolute;
        top: 0;
        width: 100%;
    }
    
    h1, h2, h3 {
        color: $white;
        font-weight: 600;
    }
    
    &__text {
        color: $blueLight;
        font-size: 1.125rem;
        position: relative;
    }
    
    @include media($screen-sm) {
        .contain {
            align-items: stretch;
            display: flex;
        }
        
        &__featured-text {
            border: 1.25rem solid $gold;
            border-left: 0;
            margin-bottom: -1.25rem;
            margin-top: -1.25rem;
            padding: #{$spacing * 3} #{$spacing * 2};
            width: 35%;
        }
        
        &__text {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%);
            align-items: center;
            display: flex;
            padding: #{$spacing * 3};
            width: 65%;
        }
    }
    
    @include media($screen-sm-max, 'max') {
        .contain {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%);
            padding-bottom: #{$spacing * 2};
            padding-top: #{$spacing * 2};
        }
    }
}