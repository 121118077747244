.featured-news {
    hr {
        border-top: 4px solid $gold;
        width: 5rem;
    }
    
    .contain {
        align-items: flex-start;
        display: flex;
        gap: #{$spacing * 2};
    }
    
    &__cta {
        background-color: $whiteDark;
        padding: $spacing;
        
        .button {
            background-color: $gold !important;
            border-color: $gold !important;
            
            &:active,
            &:focus,
            &:hover {
                background-color: $white !important;
                color: $gold !important;
            }
        }
    }
    
    &__date {
        width: 35%;
    }
    
    &__post {
        display: flex;
        
        &:not(:last-child) {
            border-bottom: 1px solid $whiteDark;
            margin-bottom: $spacing;
            padding-bottom: $spacing;
        }
    }
    
    &__text {
        width: 65%;
        
        a {
            color: $gold;
            text-decoration: none;
        }
    }
    
    @include media($screen-sm) {
        .contain {
            display: flex;
        }
        
        &__cta {
            width: calc(40% - #{$spacing};
        }
        
        &__posts {
            width: calc(60% - #{$spacing};
        }
    }
    
    @include media($screen-md) {
        .contain {
            gap: #{$spacing * 4};
        }
        
        &__cta {
            width: calc(35% - #{$spacing * 2};
        }
        
        &__posts {
            width: calc(65% - #{$spacing * 2};
        }
    }
    
    @include media($screen-sm-max, 'max') {
        .contain {
            flex-direction: column;
        }
    }
}