/**
 * DBS Slate Front Page Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will only be loaded on the front page.
 */
/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Used for mimicking the vertical space provided by the default-margin mixin, but
 * with padding for colored background layouts.
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Fonts
 */
/**
 * Type style definitions
 */
/**
 * CTA w/ Background Flex Layout
 * DBS>Interactive
 */
.cta-with-background {
  position: relative; }
  .cta-with-background hr {
    border-top: 2px solid #BF8E40;
    width: 5rem; }
  .cta-with-background .content {
    padding-left: 7.5rem;
    position: relative;
    z-index: 10; }
  .cta-with-background .eyebrow {
    color: #BF8E40; }
  .cta-with-background .image-wrapper {
    height: 100%;
    position: absolute;
    top: 0; }
    .cta-with-background .image-wrapper img {
      height: 100%;
      max-width: none;
      object-fit: cover;
      object-position: left;
      width: 100vw; }
    .cta-with-background .image-wrapper.gold-gradient::before {
      background: linear-gradient(72deg, rgba(4, 4, 4, 0) 4.11%, rgba(0, 0, 0, 0.51) 55.08%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
    .cta-with-background .image-wrapper.gold-gradient .overlays-one,
    .cta-with-background .image-wrapper.gold-gradient .overlays-two {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%; }
    .cta-with-background .image-wrapper.gold-gradient .overlays-one::before {
      background: linear-gradient(0deg, #000 0%, #000 100%), linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      mix-blend-mode: color;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2; }
    .cta-with-background .image-wrapper.gold-gradient .overlays-one::after {
      background: linear-gradient(108deg, rgba(126, 91, 45, 0.44) 10.87%, rgba(239, 214, 131, 0.44) 82.01%), linear-gradient(0deg, #EFD683 0%, #EFD683 100%), linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      mix-blend-mode: overlay;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 3; }
    .cta-with-background .image-wrapper.gold-gradient .overlays-two::before {
      opacity: 0.76;
      background: linear-gradient(106deg, #7E5B2D 19.5%, #EFD683 87.63%), linear-gradient(0deg, #EFD683 0%, #EFD683 100%), linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      mix-blend-mode: overlay;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 4; }
    .cta-with-background .image-wrapper.gold-gradient .overlays-two::after {
      background: linear-gradient(278deg, rgba(4, 4, 4, 0.04) 0%, #37260F 73.19%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5; }
  .cta-with-background .main-heading {
    font-family: Lato, Verdana, Arial, sans-serif;
    text-transform: uppercase; }
  @media screen and (min-width: 48em) {
    .cta-with-background.bg-dark.layout {
      padding-bottom: 6rem;
      padding-top: 6rem; }
    .cta-with-background.dots .content::before {
      background-image: url("/images/dots2.svg");
      background-repeat: no-repeat;
      content: '';
      height: 100%;
      left: -5rem;
      pointer-events: none;
      position: absolute;
      top: 65%;
      transform: translateY(-50%);
      width: 100%;
      z-index: 6; }
    .cta-with-background .content.half {
      width: 75%; } }
  @media screen and (min-width: 62em) {
    .cta-with-background .content.half {
      width: 65%; } }
  @media screen and (min-width: 75em) {
    .cta-with-background .content.half {
      width: 50%; } }
  @media screen and (max-width: 47.999em) {
    .cta-with-background .content {
      padding: 0;
      padding-bottom: 30%; }
    .cta-with-background .image-wrapper img {
      object-fit: contain;
      object-position: bottom; }
    .cta-with-background .image-wrapper.gold-gradient .overlays-two::after {
      background: linear-gradient(0deg, rgba(4, 4, 4, 0.04) -15%, #37260f 25%); } }

.featured-news hr {
  border-top: 4px solid #BF8E40;
  width: 5rem; }

.featured-news .contain {
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  gap: 3rem; }

.featured-news__cta {
  background-color: #F8F7F8;
  padding: 1.5rem; }
  .featured-news__cta .button {
    background-color: #BF8E40 !important;
    border-color: #BF8E40 !important; }
    .featured-news__cta .button:active, .featured-news__cta .button:focus, .featured-news__cta .button:hover {
      background-color: #ffffff !important;
      color: #BF8E40 !important; }

.featured-news__date {
  width: 35%; }

.featured-news__post {
  display: -ms-flexbox;
  display: flex; }
  .featured-news__post:not(:last-child) {
    border-bottom: 1px solid #F8F7F8;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem; }

.featured-news__text {
  width: 65%; }
  .featured-news__text a {
    color: #BF8E40;
    text-decoration: none; }

@media screen and (min-width: 48em) {
  .featured-news .contain {
    display: -ms-flexbox;
    display: flex; }
  .featured-news__cta {
    width: calc(40% - 1.5rem); }
  .featured-news__posts {
    width: calc(60% - 1.5rem); } }

@media screen and (min-width: 62em) {
  .featured-news .contain {
    gap: 6rem; }
  .featured-news__cta {
    width: calc(35% - 3rem); }
  .featured-news__posts {
    width: calc(65% - 3rem); } }

@media screen and (max-width: 47.999em) {
  .featured-news .contain {
    -ms-flex-direction: column;
    flex-direction: column; } }

.featured-text-strip {
  background-color: #52446F;
  color: #ffffff;
  position: relative;
  z-index: 10; }
  .featured-text-strip::after {
    content: '';
    height: 100%;
    left: 35%;
    position: absolute;
    top: 0;
    width: 100%; }
  .featured-text-strip h1, .featured-text-strip h2, .featured-text-strip h3 {
    color: #ffffff;
    font-weight: 600; }
  .featured-text-strip__text {
    color: #C2D1D9;
    font-size: 1.125rem;
    position: relative; }
  @media screen and (min-width: 48em) {
    .featured-text-strip .contain {
      -ms-flex-align: stretch;
      align-items: stretch;
      display: -ms-flexbox;
      display: flex; }
    .featured-text-strip__featured-text {
      border: 1.25rem solid #BF8E40;
      border-left: 0;
      margin-bottom: -1.25rem;
      margin-top: -1.25rem;
      padding: 4.5rem 3rem;
      width: 35%; }
    .featured-text-strip__text {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      padding: 4.5rem;
      width: 65%; } }
  @media screen and (max-width: 47.999em) {
    .featured-text-strip .contain {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
      padding-bottom: 3rem;
      padding-top: 3rem; } }

.home-hero {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  position: relative; }
  .home-hero__background-image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
    .home-hero__background-image img {
      height: 100%;
      max-width: none;
      object-fit: cover;
      width: 100%; }
  .home-hero__brand {
    cursor: pointer; }
  .home-hero__intro {
    position: relative;
    width: 90%; }
  @media screen and (min-width: 48em) {
    .home-hero__intro {
      width: 48%; }
    .home-hero .contain {
      padding: 18rem 1.5rem 0; } }
  .home-hero.citadel-healthcare, .home-hero.pavilion-healthcare {
    background-color: #ffffff; }
    .home-hero.citadel-healthcare a, .home-hero.pavilion-healthcare a {
      color: #FCEABE;
      text-decoration: none; }
    .home-hero.citadel-healthcare::before, .home-hero.pavilion-healthcare::before {
      background: linear-gradient(0deg, #020202 0%, #27282A 100%);
      content: '';
      height: 60%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .home-hero.citadel-healthcare .contain, .home-hero.pavilion-healthcare .contain {
      max-width: 90rem;
      padding: 9rem 1.5rem 0 0; }
    .home-hero.citadel-healthcare .home-hero__background-image, .home-hero.pavilion-healthcare .home-hero__background-image {
      max-width: 67.5rem; }
    .home-hero.citadel-healthcare .home-hero__intro, .home-hero.pavilion-healthcare .home-hero__intro {
      background: linear-gradient(270deg, #403158 0%, #020202 100%);
      border-right: 1rem solid #BF8E40;
      padding: 1.5rem; }
    @media screen and (min-width: 48em) {
      .home-hero.citadel-healthcare .contain, .home-hero.pavilion-healthcare .contain {
        padding: 16.5rem 1.5rem 7.5rem 0; }
      .home-hero.citadel-healthcare .home-hero__background-image, .home-hero.pavilion-healthcare .home-hero__background-image {
        left: unset;
        right: 0;
        width: 75%; }
      .home-hero.citadel-healthcare .home-hero__intro, .home-hero.pavilion-healthcare .home-hero__intro {
        padding: 3rem 1.5rem 3rem; }
      .home-hero.citadel-healthcare.dots::after, .home-hero.pavilion-healthcare.dots::after {
        background-image: url("/images/dots2.svg");
        background-repeat: no-repeat;
        bottom: -9rem;
        content: '';
        height: 25rem;
        right: 0;
        position: absolute;
        width: 6.1rem;
        z-index: 6; } }
    @media screen and (min-width: 62em) {
      .home-hero.citadel-healthcare .home-hero__intro, .home-hero.pavilion-healthcare .home-hero__intro {
        padding: 3rem 4.5rem 3rem 7.5rem; } }
    @media screen and (min-width: 90rem) {
      .home-hero.citadel-healthcare .home-hero__background-image, .home-hero.pavilion-healthcare .home-hero__background-image {
        left: calc(50% - 22.5rem);
        right: unset; }
      .home-hero.citadel-healthcare.dots::after, .home-hero.pavilion-healthcare.dots::after {
        right: calc(50vw - 45rem); } }
    @media screen and (max-width: 47.999em) {
      .home-hero.citadel-healthcare .home-hero__background-image:before, .home-hero.pavilion-healthcare .home-hero__background-image:before {
        background: linear-gradient(0deg, rgba(39, 41, 43, 0) 0%, #0A0A0B 100%);
        content: '';
        height: 20%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%; }
      .home-hero.citadel-healthcare .home-hero__background-image:after, .home-hero.pavilion-healthcare .home-hero__background-image:after {
        background: linear-gradient(180deg, rgba(39, 41, 43, 0.4) 0%, #27282A 100%);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%; }
      .home-hero.citadel-healthcare .home-hero__intro, .home-hero.pavilion-healthcare .home-hero__intro {
        background: transparent;
        border: 0; } }
  .home-hero.omnia-hcg .contain {
    padding: 15rem 1.5rem 0;
    position: relative; }
    .home-hero.omnia-hcg .contain::after {
      background: linear-gradient(180deg, rgba(39, 41, 43, 0) 1.98%, #27282A 101.98%);
      content: '';
      height: 21rem;
      left: -500%;
      position: absolute;
      bottom: 0;
      width: 1000%; }
  .home-hero.omnia-hcg .home-hero__background-image img {
    transform: rotateY(-180deg); }
  .home-hero.omnia-hcg .home-hero__background-image::before {
    background: linear-gradient(0deg, rgba(39, 41, 43, 0) 0%, #020202 100%);
    content: '';
    height: 21rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1; }
  .home-hero.omnia-hcg .home-hero__background-image::after {
    background: linear-gradient(180deg, rgba(39, 41, 43, 0) 1.98%, #27282A 101.98%);
    content: '';
    height: 21rem;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1; }
  .home-hero.omnia-hcg .home-hero__brand {
    -ms-flex-align: center;
    align-items: center;
    background-color: black;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1.5rem;
    width: calc(50% - 0.75rem); }
    .home-hero.omnia-hcg .home-hero__brand img {
      max-height: 8rem;
      object-fit: contain; }
  .home-hero.omnia-hcg .home-hero__brands {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 1.5rem;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 13.5rem;
    position: relative;
    width: 100%;
    z-index: 1; }
    .home-hero.omnia-hcg .home-hero__brands::after {
      background-image: url("/images/dots2.svg");
      background-repeat: no-repeat;
      content: '';
      height: 100%;
      right: -90%;
      position: absolute;
      top: -120%;
      transform: 0;
      width: 100%;
      z-index: 6; }
  .home-hero.omnia-hcg .home-hero__intro h2 {
    position: relative; }
  .home-hero.omnia-hcg .home-hero__intro:before {
    background: linear-gradient(54deg, rgba(16, 16, 16, 0) 16.96%, rgba(25, 26, 28, 0.66) 66.97%), linear-gradient(8deg, #EFD683 17.22%, #7E5B2D 37.05%);
    content: '';
    height: 10rem;
    left: calc(1rem + 3px);
    position: absolute;
    bottom: -60%;
    width: 2px; }
  .home-hero.omnia-hcg .home-hero__intro:after {
    background: url("/icons/triangle.svg") center no-repeat;
    bottom: calc(-60% - .9rem);
    content: '';
    height: .5rem;
    left: 1rem;
    position: absolute;
    width: .5rem; }
  @media screen and (min-width: 48em) {
    .home-hero.omnia-hcg .home-hero__brand {
      width: calc(30% - 1.125rem); }
    .home-hero.omnia-hcg .home-hero__brands {
      width: calc(100% - 4.5rem); }
      .home-hero.omnia-hcg .home-hero__brands::after {
        right: -109%;
        top: 40%;
        transform: translateY(-50%); } }
  @media screen and (min-width: 62em) {
    .home-hero.omnia-hcg .home-hero__brand:nth-child(2) a {
      padding: 1.7rem; } }

.location-finder {
  position: relative; }
  .location-finder #search-form .search-form__container input:-internal-autofill-selected {
    background-color: black; }
  .location-finder #search-form .search-form__form {
    width: auto; }
  .location-finder #search-form #search-input {
    border: 0;
    color: #27282A;
    margin: 0;
    padding: calc(.666rem);
    transition: border-radius .5s ease, width .5s ease; }
    .location-finder #search-form #search-input:-ms-input-placeholder {
      color: #505D68; }
    .location-finder #search-form #search-input::placeholder {
      color: #505D68; }
  .location-finder__content {
    display: -ms-flexbox;
    display: flex;
    gap: 4.5rem;
    position: relative;
    z-index: 10; }
  .location-finder__heading h2 {
    font-weight: 600; }
  .location-finder__heading .eyebrow {
    color: #BF8E40;
    font-weight: 400;
    padding-left: 2rem;
    position: relative;
    text-transform: capitalize; }
    .location-finder__heading .eyebrow:before {
      background: url("/icons/locations.svg") no-repeat;
      content: '';
      height: 1.25rem;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1.25rem; }
  .location-finder__list {
    font-size: 1.125rem;
    margin-bottom: 3rem;
    padding-left: 0;
    position: relative;
    z-index: 10; }
    .location-finder__list li {
      list-style-type: none;
      padding-left: 2.5rem;
      position: relative; }
      .location-finder__list li a {
        font-weight: 400;
        text-decoration: none; }
      .location-finder__list li:before {
        background: url("/icons/list-marker-gold.svg") no-repeat;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1.25rem; }
      .location-finder__list li:not(:last-child) {
        margin-bottom: 1.5rem; }
  .location-finder__lists {
    margin-bottom: 0;
    padding-left: 0; }
    .location-finder__lists > li {
      list-style-type: none;
      margin-bottom: 0.75rem; }
  .location-finder:not(.display-list) #search-form {
    margin: 0 auto;
    max-width: 16.5rem; }
  .location-finder:not(.display-list) .location-finder__content {
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0; }
  .location-finder:not(.display-list) .location-finder__heading {
    text-align: center;
    width: auto; }
  .location-finder:not(.display-list) .main-heading {
    color: #EFD683; }
  .location-finder .contain {
    position: relative;
    z-index: 5; }
  .location-finder .image-wrapper {
    height: 100%;
    position: absolute;
    top: 0; }
    .location-finder .image-wrapper img {
      height: 100%;
      max-width: none;
      object-fit: cover;
      object-position: left;
      width: 100vw; }
    .location-finder .image-wrapper::after {
      background: linear-gradient(180deg, #212121 0%, transparent 30%, transparent 100%);
      mix-blend-mode: multiply;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
  @media screen and (min-width: 30em) {
    .location-finder__lists {
      columns: 2; } }
  @media screen and (min-width: 48em) {
    .location-finder__content {
      padding-left: 7.5rem; }
    .location-finder__heading {
      width: 40%; }
    .location-finder__lists {
      columns: 2;
      width: 60%; }
    .location-finder.bg-dark.layout {
      padding-bottom: 6rem;
      padding-top: 6rem; }
    .location-finder.dots .location-finder__content::before {
      background-image: url("/images/dots2.svg");
      background-repeat: no-repeat;
      bottom: -9.7rem;
      content: '';
      height: 100%;
      left: -5rem;
      position: absolute;
      width: 6.1rem;
      z-index: 6; } }
  @media screen and (min-width: 62em) {
    .location-finder__content {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; }
    .location-finder.dots .location-finder__content::before {
      bottom: -18.7rem; } }
  @media screen and (max-width: 47.999em) {
    .location-finder__content {
      -ms-flex-direction: column;
      flex-direction: column; } }

/**
 * Three Columns Flex Layout
 * DBS>Interactive
 */
.layout.three-columns {
  background-color: #ffffff; }
  .layout.three-columns h1, .layout.three-columns h2, .layout.three-columns h3 {
    color: #BF8E40; }
  .layout.three-columns p {
    color: #676767; }
  .layout.three-columns .three-columns__left,
  .layout.three-columns .three-columns__middle,
  .layout.three-columns .three-columns__right {
    width: 100%; }
    @media screen and (min-width: 48em) {
      .layout.three-columns .three-columns__left,
      .layout.three-columns .three-columns__middle,
      .layout.three-columns .three-columns__right {
        width: 33.33%; } }
  .layout.three-columns .contain {
    gap: 1.5rem; }
  @media screen and (max-width: 47.999em) {
    .layout.three-columns .column .cell {
      padding-left: 0;
      padding-right: 0; } }
  @media screen and (min-width: 62em) {
    .layout.three-columns {
      /**
		 * This little bit of hackery makes the edge of background images and
		 * background colors line up with the container content when a contain
		 * class is on the half and half layout, but not when the
		 * 'contain-full-width' reset class is used.
		 */ }
      .layout.three-columns[class*=contain]:not([class~=contain-full-width]) [class*=bg-].column:first-of-type, .layout.three-columns[class*=contain]:not([class~=contain-full-width]) [class*=bg-].column:last-of-type {
        width: calc(33.3333% - 1.5rem); }
      .layout.three-columns[class*=contain]:not([class~=contain-full-width]) [class*=bg-].column:first-of-type {
        margin-left: 1.5rem; }
      .layout.three-columns[class*=contain]:not([class~=contain-full-width]) [class*=bg-].column:last-of-type {
        margin-right: 1.5rem; }
      .layout.three-columns .columns {
        margin: 0 -1.5rem; } }

/**
 * Two Columns Flex Layout
 * DBS Interactive
 */
.two-columns hr {
  border-top: 2px solid #BF8E40;
  margin: 1.5rem 0;
  width: 5rem; }

.two-columns__left, .two-columns__right {
  position: relative; }

.two-columns.left-framed.big-o-left .two-columns__left, .two-columns.big-o-left .two-columns__left {
  position: relative; }
  .two-columns.left-framed.big-o-left .two-columns__left h1, .two-columns.left-framed.big-o-left .two-columns__left h2, .two-columns.left-framed.big-o-left .two-columns__left h3, .two-columns.left-framed.big-o-left .two-columns__left h4, .two-columns.left-framed.big-o-left .two-columns__left h5, .two-columns.left-framed.big-o-left .two-columns__left h6, .two-columns.left-framed.big-o-left .two-columns__left hr, .two-columns.left-framed.big-o-left .two-columns__left p, .two-columns.big-o-left .two-columns__left h1, .two-columns.big-o-left .two-columns__left h2, .two-columns.big-o-left .two-columns__left h3, .two-columns.big-o-left .two-columns__left h4, .two-columns.big-o-left .two-columns__left h5, .two-columns.big-o-left .two-columns__left h6, .two-columns.big-o-left .two-columns__left hr, .two-columns.big-o-left .two-columns__left p {
    position: relative; }
  .two-columns.left-framed.big-o-left .two-columns__left:before, .two-columns.big-o-left .two-columns__left:before {
    background-position: center;
    background-image: url("/images/big-o.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
    height: 215%;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 0;
    transform: translate(-50%, -50%); }

.two-columns.left-framed.big-o-right .two-columns__right, .two-columns.big-o-right .two-columns__right {
  position: relative; }
  .two-columns.left-framed.big-o-right .two-columns__right h1, .two-columns.left-framed.big-o-right .two-columns__right h2, .two-columns.left-framed.big-o-right .two-columns__right h3, .two-columns.left-framed.big-o-right .two-columns__right h4, .two-columns.left-framed.big-o-right .two-columns__right h5, .two-columns.left-framed.big-o-right .two-columns__right h6, .two-columns.left-framed.big-o-right .two-columns__right hr, .two-columns.left-framed.big-o-right .two-columns__right p, .two-columns.big-o-right .two-columns__right h1, .two-columns.big-o-right .two-columns__right h2, .two-columns.big-o-right .two-columns__right h3, .two-columns.big-o-right .two-columns__right h4, .two-columns.big-o-right .two-columns__right h5, .two-columns.big-o-right .two-columns__right h6, .two-columns.big-o-right .two-columns__right hr, .two-columns.big-o-right .two-columns__right p {
    position: relative; }
  .two-columns.left-framed.big-o-right .two-columns__right:before, .two-columns.big-o-right .two-columns__right:before {
    background-position: center;
    background-image: url("/images/big-o.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
    height: 215%;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 0;
    transform: translate(-50%, -50%); }

.two-columns.left-framed .contain {
  gap: 3rem; }

.two-columns.left-framed .two-columns__left {
  margin: 0 1rem 4rem;
  position: relative; }
  .two-columns.left-framed .two-columns__left .container {
    background-color: white;
    color: #676767;
    padding: 1.5rem;
    position: relative; }
    .two-columns.left-framed .two-columns__left .container h1, .two-columns.left-framed .two-columns__left .container h2, .two-columns.left-framed .two-columns__left .container h3, .two-columns.left-framed .two-columns__left .container h4, .two-columns.left-framed .two-columns__left .container h5, .two-columns.left-framed .two-columns__left .container h6 {
      color: #676767;
      font-family: Lato, Verdana, Arial, sans-serif; }
  .two-columns.left-framed .two-columns__left::before {
    background: linear-gradient(180deg, #9C712B 0%, #FCB315 100%);
    content: '';
    height: 85%;
    position: absolute;
    left: -1rem;
    top: calc(15% + 1rem);
    width: calc(100% + 2rem); }

@media screen and (max-width: 47.999em) {
  .two-columns__left {
    margin-bottom: 1.5rem; } }

@media screen and (min-width: 48em) {
  .two-columns.left-framed .two-columns__left {
    margin: 0 3rem; }
  .two-columns__left, .two-columns__right {
    width: calc(50% - 3rem); }
    .two-columns__left.contain-img img, .two-columns__right.contain-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.contain-img img, .two-columns__right.contain-img img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .two-columns__left.cover img, .two-columns__right.cover img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.cover img, .two-columns__right.cover img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .two-columns__left.contain-img, .two-columns__left.cover, .two-columns__right.contain-img, .two-columns__right.cover {
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch; } }

@media screen and (min-width: 62em) {
  .two-columns__left > img:only-child,
  .two-columns__right > img:only-child {
    height: 100%; } }

.admin {
  opacity: .75;
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  transition: opacity .25s; }
  .admin.active, .admin:hover, .admin:focus {
    opacity: 1; }
  .admin.active .admin__links {
    opacity: 1;
    transition: opacity .25s 0s, visibility 0s .0s;
    visibility: visible; }
  .admin__toggle {
    appearance: none;
    background: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1rem -0.5rem rgba(2, 2, 2, 0.75);
    height: 4rem;
    position: relative;
    width: 4rem; }
    .admin__toggle img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 75%;
      left: 55%;
      width: 75%; }
      @supports (object-fit: contain) {
        .admin__toggle img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
  .admin__links {
    background: white;
    border-radius: 1rem;
    box-shadow: 0 0 1rem -0.5rem rgba(2, 2, 2, 0.75);
    opacity: 0;
    padding: 1rem 1.5rem;
    position: absolute;
    bottom: 4.5rem;
    right: 0;
    transition: opacity .25s 0s, visibility 0s .25s;
    visibility: hidden; }
    .admin__links ul {
      list-style: none;
      margin: 0;
      text-align: right;
      padding: 0; }
      .admin__links ul li {
        margin-bottom: .5rem; }
        .admin__links ul li:last-child {
          margin-bottom: 0; }
        .admin__links ul li a {
          display: block;
          text-decoration: none; }
          .admin__links ul li a:after {
            background: #020202;
            content: '';
            display: block;
            height: 1px;
            position: relative;
            top: 0;
            transform: scaleX(0);
            transform-origin: 0 100%;
            transition: transform .25s ease-in-out; }
          .admin__links ul li a:hover, .admin__links ul li a:focus {
            text-decoration: none; }
            .admin__links ul li a:hover:after, .admin__links ul li a:focus:after {
              transform: scaleX(1);
              transform-origin: 100% 0; }

/**
 * DBS Chevron Styles
 * DBS>Interactive
 */
#dbs-chev {
  position: relative; }
  #dbs-chev svg {
    height: 2rem;
    max-width: 1rem; }
  #dbs-chev strong {
    font-size: 0.64em;
    float: right;
    font-weight: normal;
    text-align: center;
    width: 15em; }
    @media (min-width: 48em) {
      #dbs-chev strong {
        font-size: 0.8em; } }
    @media screen and (min-width: 48em) {
      #dbs-chev strong {
        width: 21em; } }
  #dbs-chev .text {
    background: #020202;
    max-width: 0;
    overflow: hidden;
    padding: .25em 0;
    position: absolute;
    right: 2em;
    top: 1px;
    transition: all .5s ease-out; }
  #dbs-chev.hover span, #dbs-chev:hover span, #dbs-chev:focus span {
    max-width: 23em; }

/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */
.site-footer {
  clear: both;
  font-size: .875rem;
  margin-top: auto;
  overflow: hidden;
  position: relative; }
  .site-footer__container {
    padding: 3rem 1.5rem 0.75rem; }
  .site-footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .site-footer a {
    text-decoration: none; }
    .site-footer a:hover {
      text-decoration: underline; }
  .site-footer .site-footer__logo svg {
    display: block;
    max-width: 10em;
    padding-bottom: 1em; }
  .site-footer__content {
    display: inline-block; }
  .site-footer__business ul, .site-footer__business li {
    display: block; }
  .site-footer__navigation {
    font-family: Lato, Verdana, Arial, sans-serif; }
    .site-footer__navigation .footer-menu__item a {
      display: block;
      font-weight: 400;
      padding: .25rem 0; }
      .site-footer__navigation .footer-menu__item a:after {
        background: #27282A;
        content: '';
        display: block;
        height: 1px;
        position: relative;
        top: 3px;
        transform: scaleX(0);
        transform-origin: 100% 0;
        transition: transform .25s ease-in-out; }
    .site-footer__navigation .footer-menu__item.toplevel {
      margin-bottom: 1rem; }
      .site-footer__navigation .footer-menu__item.toplevel > a {
        color: #ffffff;
        font-weight: 700;
        letter-spacing: 1px; }
    .site-footer__navigation .submenu-toggle {
      display: none; }
    .site-footer__navigation > ul {
      column-gap: 3rem; }
      .site-footer__navigation > ul > .footer-menu__item > a:after {
        background: #403158; }
      .site-footer__navigation > ul > li > a {
        margin-bottom: 1rem;
        padding: 0 !important; }
    .site-footer__navigation li {
      display: block; }
  .site-footer .social-media {
    margin: 1rem 0; }
    .site-footer .social-media__link {
      display: inline-block; }
    .site-footer .social-media a {
      display: block;
      width: 2em; }
      .site-footer .social-media a:active svg path, .site-footer .social-media a:focus svg path, .site-footer .social-media a:hover svg path {
        fill: #505D68; }
    .site-footer .social-media li:not(:last-child) {
      margin-right: 1rem; }
    .site-footer .social-media svg {
      height: 1rem; }
      .site-footer .social-media svg path {
        transition: .5 color ease; }
  .site-footer__credits {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1em 0 0;
    width: 100%; }
    .site-footer__credits .legal-footer {
      display: inline-block;
      font-family: Lato, Verdana, Arial, sans-serif; }
      .site-footer__credits .legal-footer .legal-menu__item a {
        font-weight: 400; }
  .site-footer.bg-dark .site-footer__navigation .footer-menu__item a:after {
    background: #ffffff; }
  .site-footer.bg-dark .site-footer__navigation > ul > .footer-menu__item > a:after {
    background: #EFD683; }
  .site-footer.bg-dark .social-media a:active svg path, .site-footer.bg-dark .social-media a:focus svg path, .site-footer.bg-dark .social-media a:hover svg path {
    fill: #C2D1D9; }
  .site-footer.bg-dark .social-media svg path {
    fill: #ffffff; }
  @media screen and (min-width: 48em) {
    .site-footer__business {
      column-gap: 3rem;
      columns: 2; }
    .site-footer__content {
      width: 40%; }
      .site-footer__content > div > a {
        color: #403158; }
    .site-footer__credits .legal-footer ul, .site-footer__credits .legal-footer li {
      display: inline-block; }
    .site-footer__credits .legal-footer .legal-menu__item:not(:last-child) {
      margin-right: 3rem; }
    .site-footer__main {
      padding-bottom: 3rem; }
    .site-footer__navigation {
      width: 100%; }
      .site-footer__navigation > ul {
        -ms-flex-align: start;
        align-items: flex-start;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between; }
        .site-footer__navigation > ul > li {
          min-width: 8rem; }
          .site-footer__navigation > ul > li > a {
            color: #403158;
            font-weight: bold !important;
            text-transform: uppercase; }
      .site-footer__navigation .footer-menu__item a:hover, .site-footer__navigation .footer-menu__item a:focus {
        text-decoration: none; }
        .site-footer__navigation .footer-menu__item a:hover:after, .site-footer__navigation .footer-menu__item a:focus:after {
          transform: scaleX(1);
          transform-origin: 0 100%; }
      .site-footer__navigation .footer-menu__item .toplevel {
        padding-right: 1.5rem; }
    .site-footer .social-media {
      margin: 3rem 0; }
      .site-footer .social-media a:active svg path, .site-footer .social-media a:focus svg path, .site-footer .social-media a:hover svg path {
        fill: #875E1D; }
      .site-footer .social-media svg path {
        fill: #BF8E40; }
    .site-footer::after {
      background-color: #C2D1D9;
      content: '';
      height: 1px;
      position: absolute;
      bottom: 3rem;
      width: 100%; }
    .site-footer.bg-dark {
      padding: 3rem 0 0; }
      .site-footer.bg-dark .site-footer__content > div > a {
        color: #EFD683; }
      .site-footer.bg-dark .site-footer__navigation > ul > li > a {
        color: #EFD683; }
      .site-footer.bg-dark .social-media a:active svg path, .site-footer.bg-dark .social-media a:focus svg path, .site-footer.bg-dark .social-media a:hover svg path {
        fill: #EFD683; }
      .site-footer.bg-dark::before {
        background-color: #C2D1D9;
        content: '';
        height: 1px;
        position: absolute;
        top: 3rem;
        width: 100%; } }
  @media screen and (min-width: 62em) {
    .site-footer__main {
      display: -ms-flexbox;
      display: flex;
      gap: 6rem; }
    .site-footer__navigation {
      width: 60%; }
      .site-footer__navigation > ul {
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly; } }
  @media screen and (max-width: 47.999em) {
    .site-footer {
      text-align: center; }
      .site-footer__business .site-footer__phone-email {
        margin-top: 1rem; }
      .site-footer__container {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0; }
      .site-footer__credits {
        background-color: #C2D1D9;
        padding: 1rem 1.5rem; }
        .site-footer__credits .legal-footer {
          margin-bottom: 1.5rem; }
      .site-footer__navigation {
        background-color: #C2D1D9;
        margin: 2rem 0 0; }
        .site-footer__navigation .footer-menu__submenu {
          display: none; }
        .site-footer__navigation .footer-menu__item {
          margin-bottom: 1rem;
          padding-bottom: 1rem; }
          .site-footer__navigation .footer-menu__item--parent {
            position: relative; }
            .site-footer__navigation .footer-menu__item--parent.open .footer-menu__submenu {
              display: block; }
          .site-footer__navigation .footer-menu__item:first-child {
            padding-top: 1rem; }
          .site-footer__navigation .footer-menu__item:last-child {
            margin-bottom: 0;
            padding-bottom: 2rem; }
          .site-footer__navigation .footer-menu__item:not(:last-child) {
            border-bottom: 1px solid #fff; }
          .site-footer__navigation .footer-menu__item a {
            margin-bottom: 0; }
        .site-footer__navigation .submenu-toggle {
          top: -.75rem; }
          .site-footer__navigation .submenu-toggle svg path {
            fill: #4D4D4D; }
        .site-footer__navigation > ul > li > a {
          font-size: 1rem; }
      .site-footer.bg-dark .site-footer__credits {
        background-color: #25303A; }
      .site-footer.bg-dark .site-footer__navigation {
        background-color: #25303A; }
      .site-footer .social-media {
        -ms-flex-pack: center;
        justify-content: center; } }

/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */
